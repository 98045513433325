import * as React from "react"
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai"
import { SiTiktok } from "react-icons/si"
import { Button, Flex, HStack, Icon, Image, Link, SimpleGrid, Stack, Text } from "@chakra-ui/react"
import dayjs from "dayjs"
import NextLink from "next/link"

import { Limiter } from "./Limiter"

export function Footer() {
  // const handleShowNewsletter = () => {
  //   const elements = document.getElementsByClassName("eprov-cs")
  //   if (elements.length === 0) return
  //   elements[0].removeAttribute("style") // reset style attr
  //   elements[0].setAttribute("style", "display: inline-block !important;")

  //   // // global function name can be found by going to https://app.laposta.nl/c.listconfig/s.memberforms/t.subscribe
  //   // // finding the aanmeldenformulieer, configuration, then in the settings you will at the bottom
  //   // // (it's also just a combo of the script src url)

  //   // // // WARNING: this causes an error in prod, leaving it in as reference until I find a better solution
  //   // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // // @ts-ignore
  //   // window._wdbxbxpdd2cgbhn8o2fu.action("show")
  // }
  return (
    <Flex alignItems="flex-start" bgColor="black" py={{ base: 8, md: 16 }} textAlign="left">
      <Limiter>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 20, md: 10 }}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
            <Stack spacing={1}>
              <Link as={NextLink} passHref href="/contact" fontSize={22} fontWeight={900} color="white">
                Contact
              </Link>
              <Link
                as={NextLink}
                passHref
                href="/over-kunstbende"
                fontSize={22}
                fontWeight={900}
                color="white"
              >
                Over ons
              </Link>
              <Link as={NextLink} passHref href="/partners" fontSize={22} fontWeight={900} color="white">
                Partners
              </Link>
              <Link as={NextLink} passHref href="/docenten" fontSize={22} fontWeight={900} color="white">
                Docenten
              </Link>
              <Link as={NextLink} passHref href="/vacatures" fontSize={22} fontWeight={900} color="white">
                Vacatures
              </Link>
              <Link
                href="https://kunstbende.sumupstore.com"
                isExternal
                fontSize={22}
                fontWeight={900}
                color="white"
                aria-label="Open kunstbende winkel (wordt geopend in een nieuw tabblad)"
              >
                Shop
              </Link>
            </Stack>

            <Stack spacing={1}>
              <Button
                variant="link"
                onClick={() => {
                  // @ts-ignore
                  window._wdbxbxpdd240c4luiual.action("show")
                }}
                fontSize={22}
                fontWeight={900}
                color="white"
                justifyContent="flex-start"
              >
                <Text _hover={{ textDecor: "underline" }}>Membernieuwsbrief</Text>
              </Button>
              <Link as={NextLink} passHref href="/vragen" fontSize={22} fontWeight={900} color="white">
                Vragen
              </Link>
              <Link
                as={NextLink}
                passHref
                href="/toegankelijkheid"
                fontSize={22}
                fontWeight={900}
                color="white"
              >
                Toegankelijkheid
              </Link>
              <Link as={NextLink} passHref href="/veiligheid" fontSize={22} fontWeight={900} color="white">
                Veiligheid
              </Link>
              <Link as={NextLink} passHref href="/privacy" fontSize={22} fontWeight={900} color="white">
                Privacy
              </Link>
              <Link as={NextLink} passHref href="/disclaimer" fontSize={22} fontWeight={900} color="white">
                Disclaimer
              </Link>
            </Stack>
          </SimpleGrid>

          <Stack spacing={10} color="white" textAlign={{ base: "left", md: "right" }}>
            <HStack justify={{ base: "flex-start", md: "flex-end" }}>
              <Link
                href="https://www.instagram.com/kunstbende/"
                isExternal
                aria-label="Open Kunstbende Instagram (wordt geopend in een nieuw tabblad)"
              >
                <Icon color="yellow.500" bg="transparent" as={AiOutlineInstagram} boxSize="32px" />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCKUvgNYKFNyfVTI0X8svqTQ"
                isExternal
                aria-label="Open Kunstbende Youtube (wordt geopend in een nieuw tabblad)"
              >
                <Icon color="yellow.500" bg="transparent" as={AiOutlineYoutube} boxSize="32px" />
              </Link>
              <Link
                href="https://www.tiktok.com/@kunstbende?"
                isExternal
                aria-label="Open Kunstbende TikTok (wordt geopend in een nieuw tabblad)"
              >
                <Icon color="yellow.500" bg="transparent" as={SiTiktok} boxSize="32px" />
              </Link>
            </HStack>
            <Stack alignSelf={{ base: "flex-start", md: "flex-end" }}>
              <Image
                alt="kunstbende logo"
                src="/logo.png"
                h="48px"
                alignSelf={{ base: "flex-start", md: "flex-end" }}
              />
              <Text fontSize={14} fontWeight={900} color="yellow.500">
                Kunstbende {dayjs().get("y")} ©
              </Text>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Limiter>
    </Flex>
  )
}
